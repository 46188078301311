import React from 'react';

import './App.css';
import SocialLinks from './components/SocialLinks';

function App() {
  return (
    <div className="App">
      
      <header className="App-header">
        <h1>My Social Profiles</h1>
        <SocialLinks />
      </header>
    </div>
  );
}

export default App;
